body {
  font-family: 'Arial', sans-serif;
  background-color: #fcfcfc;
  width: 100%;
  margin: 0;
  font-size: 18px;
  -webkit-overflow-scrolling: touch;
}

*{
  box-sizing: border-box;
  text-decoration: none;
}

a{
  color: #000000;
}

#errorpage{
  text-align: center;
  font-weight: bold;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loadingicon{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

header{
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0 5px;
  top: 0;
  font-weight: bold;
  z-index: 100;
  width: 100%;
  vertical-align: middle;
  display: table-row;
  position: absolute;
}

header span{
  height: 50px;
  vertical-align: middle;
  display: table-cell;
}

header img{
  vertical-align: middle;
}

header .back{
  padding: 0 5px;
}

header .thumb{
  padding: 0 5px 0 10px;
}

header .thumb img{
  border-radius: 100%;
}

header .title{
  padding: 0 0 0 10px;
}

#messages .chats{
  margin: 50px 0 0 0;
}

#messages .chats ul{
  list-style: none;
  margin: 0;
  position: relative;
  height: 80px;
}

#messages .chats ul.message{
  border-bottom: 1px solid #cccccc;
  padding: 10px;
}

#messages .chats ul.message.new{
  background-color: #e6efdf;
}

#messages .chats .thumb{
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

#messages .chats .thumb img{
  border-radius: 100%;
}

#messages .chats .name{
  font-weight: bold;
  position: absolute;
  left: 90px;
  top: 10px;
}

#messages .chats .lastmessage{
  font-size: 90%;
  position: absolute;
  left: 90px;
  top: 35px;
  font-style: italic;
}

#messages .chats .messagesnum{
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 70%;
  border-radius: 100%;
  background-color: #2e7d32;
  padding: 5px;
  color: #ffffff;
  width: 22px;
  height: 22px;
}

#messages .chats .messagesnum span{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#chat .usertyping{
  text-align: center;
  padding: 0;
  margin: 0;
}

#chat .swipeable-list{
  padding: 60px 10px 70px 10px;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  background-color: #e5ddd5;
}

#chat .swipeable-list-item{
  margin-bottom: 10px;
  border-radius: 4px;
  max-height: inherit;
  padding-bottom: 6px;
}

#chat .swipeable-list-item .message{
  background-color: #ffffff;
  padding: 15px;
  margin: 0;
  word-wrap: break-word;
  position: relative;
  width: 65%;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
}

#chat .swipeable-list-item .message.current{
  background-color: #dcf8c6;
  margin-left: 35%;
}

#chat .swipeable-list-item__content-left{
  background-color: #d9534f;
  color: #ffffff;
  font-weight: bold;
  padding: 0 20px 0 0;
  width: 65%;
  margin-left: 35%;
  height: calc(100% - 6px);
  border-radius: 4px;
}

#chat .swipeable-list-item__content{
  background-color: inherit;
}

#chat .message .information{
  color: #757575;
  text-align: right;
  padding: 0;
  margin: 10px 0 0 0;
  font-size: 65%;
  font-style: italic;
}

#chat .send-form{
  background-color: #fafafa;
  border-top:1px solid #cccccc;
  padding: 10px 50px 10px 10px;
  width: 100%;
  box-sizing: border-box;
  height: 60px;
  position: absolute;
  bottom: 0;
}

#chat .send-form .btn-send{
  background-color: #d9534f;
  position: absolute;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  border: 0;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  padding: 8px;
  cursor: pointer;
}

#chat .send-form .message {
  font-size: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0;
  resize: none;
  background-color: inherit;
}

#chat .send-form .message:focus {
  box-shadow: none;
  outline: 0;
  border-radius: 5px;
}

.react-confirm-alert-overlay{
  z-index: 1000;
  background: rgba(128,128,128,0.5);
}

.react-confirm-alert{
  text-align: center;
  font-weight: bold;
}

.react-confirm-alert-body{
  font-size: inherit;
  width: 100%;
  text-align: center;
  border-radius: 4px;
}

.react-confirm-alert-button-group{
  display: block;
  text-align: center;
}

.react-confirm-alert-button-group > button{
  margin: 10px auto;
  display: block;
  font-size: 90%;
  width: 100%;
  background-color: #d9534f;
  border: 1px #d43f3a;
  color: #ffffff;
}


.react-confirm-alert-body > h1{
  font-size: inherit;
}

@media screen and (max-width: 767px) {
  #chat .swipeable-list-item .message{
    width: 85%;
  }
  
  #chat .swipeable-list-item .message.current{
    margin-left: 15%;
  }
  
  #chat .swipeable-list-item__content-left{
    width: 85%;
    margin-left: 15%;
  }
}